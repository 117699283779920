import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Update June 7, 2018 - Hall of Shame`}</h3>
    <p>{`This article is a Hall of Shamer`}{`™`}{` for irrelevance. I have migrated from
r.js to Webpack.`}</p>
    <p>{`One of the great principles of `}<a parentName="p" {...{
        "href": "http://requirejs.org/"
      }}>{`Require.js`}</a>{` is that you
shouldn't need a build step during development, you should simply be able to
refresh the browser and see your changes reflected. This does not mean, however,
that you should also have to compromise on performance. Often times it makes
sense to modify your application's source at build time for performance reasons.
This includes minification, annotation, inlining of text dependencies, or even
transpiling one source format to another. Require.js offers the
`}<a parentName="p" {...{
        "href": "http://requirejs.org/docs/optimization.html"
      }}>{`r.js`}</a>{` build tool to optimize your
code for production as well as a robust plugin system. This article will focus
on the former, using
`}<a parentName="p" {...{
        "href": "/articles/javascript-dependency-injection.html"
      }}>{`Angular.js dependency injection annotations`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://github.com/btford/ngmin"
      }}>{`ngmin`}</a>{` as an example.`}</p>
    <h2>{`The Problem`}</h2>
    <p>{`In Angular.js a dependency injection system is used to resolve dependencies and
provide them at run time. Dependencies use constructor function argument names
to match dependencies and provide them at run time. For example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`angular`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`module`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"people"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`controller`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MyCtrl"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`$scope`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` $http`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// $scope and $http were resolved by name and provided here.`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`Please see the
`}<a parentName="p" {...{
          "href": "/articles/javascript-dependency-injection.html"
        }}>{`JavaScript Dependency Injection`}</a>{`
article for a more detailed explanation.`}</p>
    </blockquote>
    <p>{`This approach becomes problematic at the minification phase of a project because
when the argument names are mangled they can no longer be properly mapped to
dependencies.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`angular`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`module`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"people"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`controller`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MyCtrl"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` b`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// WTF is a or b?`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`For the above reason Angular.js provides a build safe approach for declaring
dependencies which involves using strings to annotate dependencies.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`angular`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`module`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"people"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`controller`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"MyCtrl"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"$scope"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"$http"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`a`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` b`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Ok so a is $scope and b is $http.`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`This certainly works and is more akin to how we declare AMD dependencies, but
doing these annotations means we duplicate our dependency declarations once in
the array annotations and again in the function arguments. Worse, since we don't
have the ability to use something like the excellent
`}<a parentName="p" {...{
        "href": "http://requirejs.org/docs/whyamd.html#sugar"
      }}>{`CommonJS sugar`}</a>{` Require.js
provides, we are forced to maintain two disparate lists of dependencies and
match them up using order instead of variable declarations.`}</p>
    <p>{`Wouldn't it be great if we could use a tool to perform these annotations for us?
Enter `}<a parentName="p" {...{
        "href": "https://github.com/btford/ngmin"
      }}>{`ngmin`}</a>{`.`}</p>
    <h3>{`ngmin`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/btford/ngmin"
      }}>{`ngmin`}</a>{` is a preprocessor which parses your
code for injectable constructor functions and annotates them automatically
making your Angular.js code "build safe".`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`ngmin somefile.js somefile.annotate.js`}</code></pre></div>
    <p>{`This command would output "somefile.annotate.js" which would be an annotated
version of some file.`}</p>
    <blockquote>
      <p parentName="blockquote">{`As a side note, ngmin also offers a
`}<a parentName="p" {...{
          "href": "https://github.com/btford/grunt-ngmin"
        }}>{`grunt task`}</a>{` and a
`}<a parentName="p" {...{
          "href": "http://rubygems.org/gems/ngmin-rails"
        }}>{`Rails Asset Pipeline plugin`}</a>{`.`}</p>
    </blockquote>
    <p>{`Using ngmin is well and good and all but we now have an additional step of added
complexity for every build we perform. A developer needs to run a concatenator
(or dependency tracer), ngmin, and then the minifier. All of this before or
after other application specific build tools. To make things worse order matters
in many of these cases so running different tasks in parallel becomes difficult.`}</p>
    <p>{`Enter `}<a parentName="p" {...{
        "href": "http://requirejs.org/docs/optimization.html"
      }}>{`r.js`}</a>{`.`}</p>
    <h3>{`r.js`}</h3>
    <p>{`r.js is the defacto build tool for AMD driven projects and thanks to its
extensible callbacks we can perform source modification using things like ngmin.
This way developers will run "r.js" causing concatenation, annotation and
minification to be taken care of by a single system. This helps reduce
complexity in a build system by decreasing the number of cognitive steps to one
instead of three.`}</p>
    <h2>{`Solution`}</h2>
    <p>{`r.js offers an excellent build hook "onBuildRead" which is invoked for each
module, the return value of this hook will be used for the built file prior to
minification. For performance reasons r.js will only invoke this on your bundled
modules by default. I recommend setting "normalizeDirDefines" to "all" which
means these modifications will be run on all files, not just your bundled
modules. The reason I make this recommendation is because I believe you should
run your unit tests after the build process and since unit tests are executed
against individual modules you will need your source modifications to run
against those as well. It is important to remember that tools like UglifyJS,
r.js or ngmin aren't flawless.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  dir`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"javascripts-built"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  baseUrl`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"javascripts"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  modules`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"MyApplication"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  normalizeDirDefines`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"all"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`onBuildRead`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`moduleName`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` path`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` contents`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"ngmin"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`annotate`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`contents`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`Now all of "MyApplication" and its child modules will be run through ngmin, and
minified afterwards. This means we can unit test those children as well. The
combination of "onBuildRead" and "normalizeDirDefines" empowers us to perform
testable source modification at build time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      